import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

const AreasServedContainer = styled.div`
  h1 {
    font-size: calc(0.75vw + 1.5em);
  }
`

export const AreasServed = (props) => {
  const {
    citiesList,
    className,
    serviceName = '',
    parentPageSlug,
    blogComponent,
  } = props

  const getServiceTitle = () => {
    if (serviceName.includes('Siding Replacement')) return 'Siding'
    if (serviceName.includes('Siding Installation')) return 'Siding'
    if (serviceName.includes('Vinyl Siding')) return 'Vinyl Siding'
    if (serviceName.includes('Roofing')) return 'Roofing'
    if (serviceName.includes('Deck')) return 'Decking'
    if (serviceName.includes('James Hardie')) return 'James Hardie Siding'
    


    if (serviceName.includes('Kitchen Remodeling')) return 'Kitchen Remodeling'
    if (serviceName.includes('Windows')) return 'Replacement Windows'
    if (serviceName.includes('Doors')) return 'Doors'
    if (serviceName.includes('Kitchen Cabinets')) return 'Kitchen Cabinets'
    if (serviceName.includes('Home Remodeling')) return 'Home Remodeling'
    if (serviceName.includes('Shower')) return 'Shower Remodeling'


    if (serviceName.includes('Bathroom Remodeling'))
      return 'Bathroom Remodeling'
    return ''
  }

  const getServiceSlug = () => {
    if (serviceName.includes('Siding Replacement')) return 'siding'
    if (serviceName.includes('Siding Installation')) return 'siding'
    if (serviceName.includes('Vinyl Siding')) return 'vinyl-siding'
    if (serviceName.includes('James Hardie')) return 'james-hardie-siding'
    if (serviceName.includes('Windows')) return 'replacement-windows'
    if (serviceName.includes('Door')) return 'replacement-windows'
    if (serviceName.includes('Roofing')) return 'roofing-contractor'
    if (serviceName.includes('Gutter')) return 'gutter-installation'
    if (serviceName.includes('Deck')) return 'deck-builder'
    if (serviceName.includes('Siding')) return 'siding'
    if (serviceName.includes('Shower')) return 'shower-replacement-installation'



    return ''
  }

  const serviceTitle = getServiceTitle()
  const serviceSlug = getServiceSlug()

  return (
    <AreasServedContainer className={className}>
      <div className="container is-service-page">
        <div className="pt-6 pb-5">
          <h2>Areas Served</h2>
          {!blogComponent ? (
            <ul className="flex flex-wrap list-none m-0">
              {citiesList.map((item, i) => (
                <li key={i} className="w-1/2 md:w-1/4 flex">
                  <div className="flex-shrink-0 pr-1">
                    <svg
                      className="h-6 w-6 text-green-500"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M5 13l4 4L19 7"
                      ></path>
                    </svg>
                  </div>{' '}
                  <Link
                    className="text-gray-800 hover:text-usq-red"
                    title={`${
                      item.node.location +
                      ' ' +
                      (serviceTitle ? serviceTitle + ' ' : '')
                    }`}
                    to={`/${serviceSlug}-${item.node.slug}/`}
                  >
                    {item.node.location}, {item.node.stateAbbreviation}
                  </Link>
                </li>
              ))}
            </ul>
          ) : (
            <ul className="flex flex-wrap list-none m-0">
              {citiesList.map((item, i) => (
                <li key={i} className="w-1/2 md:w-1/4 flex">
                  <div className="flex-shrink-0 pr-1">
                    <svg
                      className="h-6 w-6 text-green-500"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M5 13l4 4L19 7"
                      ></path>
                    </svg>
                  </div>{' '}
                  <Link
                    className="text-gray-800 hover:text-usq-red"
                    title={`${
                      item.location +
                      ' ' +
                      (serviceTitle ? serviceTitle + ' ' : '')
                    }`}
                    to={`/${serviceSlug}-${item.slug}/`}
                  >
                    {item.location}, {item.stateAbbreviation}
                  </Link>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </AreasServedContainer>
  )
}
