import React, { Component } from 'react'
import { graphql, Link } from 'gatsby'

import SEO from '../components/seo'
import Layout from '../components/layout'
import { GatsbyImage, getImage, getSrc } from 'gatsby-plugin-image'
import styled from 'styled-components'
import {
  FacebookShareButton,
  TwitterShareButton,
  EmailShareButton,
  LinkedinShareButton,
} from 'react-share'
import { Facebook, Twitter, Mail, Linkedin } from 'react-feather'

import ContactForm from '../components/estimate-form'
import { ServingCitiesText } from '../components/ServingCitiesText'

import { AreasServed } from '../components/areas-served'
import Breadcrumbs from '../components/breadcrumbs'

import { RelatedProjects } from '../components/RelatedProjects'

const ContactCard = styled.div`
  top: 30px !important;
  background: rgb(249 249 249);
`

const BlogPostLayout = styled.div`
  background: #fff;
  .articleBody {
    max-width: 900px;
    margin: 0 auto;
    padding: 1em;
  }
`
const Header = styled.div`
  text-align: left;
  margin: 0 auto;
  padding-bottom: 0em;
  margin-bottom: 1em;
  border-bottom: 1px solid #eaeaea;
  h1 {
    font-size: calc(1.15vw + 1em);
    margin-bottom: 0.25em;
    line-height: 1.3;
    font-weight: 600;
  }
  border-bottom: 1px solid #f1f1f1;
  p {
    color: #666;
  }
  background: #f7f7f7;
`

const BlogPostImage = styled.div`
  img {
    max-width: 900px;
    margn: 0 auto;
    text-align: center;
    border-radius: 0;
    margin-bottom: 1em;
    box-shadow: none !important;
  }
`
const ShareThisPost = styled.div`
  padding-bottom: 1em;
  p {
    font-weight: bold;
    margin: 0;
  }
  display: flex;
  align-items: center;
  .SocialMediaShareButton {
    display: flex;
    padding: 0 9px;
  }
  svg {
    stroke: none;
    fill: #888;
    cursor: pointer;
  }
  .stroke-white {
    stroke: white;
  }
`

class BlogTemplate extends Component {
  render() {
    const post = this.props.data.contentfulBlogPost
    const siteurl = this.props.data.site.siteMetadata.siteUrl
    const relatedPosts = this.props.data.related

    const { name: categoryName, citiesList } =
      this.props.data.contentfulBlogCategory

    return (
      <Layout pageProps={this.props}>
        <div className="bg-white border-b border-gray-100">
          <nav
            className="flex container mx-auto px-4 py-3"
            aria-label="Breadcrumb"
          >
            <Breadcrumbs
              links={[
                { to: '/', label: 'Home' },
                { to: '/blog/', label: 'Blog' },
                {
                  to: this.props.location.pathname,
                  label: post.title,
                },
              ]}
            />
          </nav>
        </div>

        <SEO
          title={post.metaTitle ? post.metaTitle : post.title}
          description={post.metaDescription ? post.metaDescription : post.title}
          img={getSrc(post.heroImage.metaTagImage)}
        />
        <BlogPostLayout className="relative">
          <article itemScope itemType="https://schema.org/Article">
            <div
              itemProp="publisher"
              itemScope
              itemType="https://schema.org/Organization"
              className="hidden"
            >
              <span itemProp="name" className="hidden">
                US Quality Construction
              </span>
              <span
                itemProp="image"
                itemScope
                itemType="http://schema.org/ImageObject"
                className="hidden"
              >
                <img
                  src="https://images.ctfassets.net/wz4em6cftltu/GBdB9YIxsN84AQrhVnl3B/9110bc57717912f5c372fd7487652001/usq-thumb.jpeg"
                  className="hidden"
                  width="262"
                  itemProp="url"
                />
              </span>
              <span
                itemProp="logo"
                itemScope
                itemType="http://schema.org/ImageObject"
                className="hidden"
              >
                <img
                  src="https://images.ctfassets.net/wz4em6cftltu/k3dicBOhCSc3einkPzkid/eb33b3bd57d726b53480f8208b03b8ca/usqc-thumb.jpg"
                  className="hidden"
                  width="262"
                  itemProp="url"
                />
              </span>
            </div>
            <Header>
              <div className="lg:flex lg:container lg:row mx-auto">
                <div
                  className="container py-12 text-left blogimgmw w-full lg:w-2/3 relative z-20 pt-8"
                  style={{ maxWidth: '900px' }}
                >
                  <h1 itemprop="name headline">{post.title}</h1>
                  <p>
                    <time itemprop="datePublished">{post.publishDate}</time> —
                    by <span itemprop="author">{post.author.name}</span> in{' '}
                    <Link
                      to={'/blog/category/' + post.category.slug}
                      title={'View all posts in ' + post.category.name}
                    >
                      {post.category.name}
                    </Link>
                  </p>

                  <br />

                  <div
                    className="mx-auto overflow-hidden rounded shadow-lg relative z-20"
                    style={{ marginBottom: '-150px' }}
                  >
                    <div
                      itemProp="image"
                      itemScope
                      itemType="http://schema.org/ImageObject"
                    >
                      <img
                        src={post.heroImage.metaTagImage.images.fallback.src}
                        className="hidden"
                        width="900"
                        height="400"
                        itemProp="url"
                        alt={post.title}
                      />

                      <GatsbyImage
                        image={getImage(post.heroImage)}
                        alt={post.title}
                        style={{
                          height: '400px',
                          objectFit: 'cover',
                          boxShadow: '1px 2px 8px 1px hsla(0,0%,0%,0)',
                        }}
                        className="blogPostImage"
                      />
                    </div>
                    <div>
                      <ShareThisPost className="bg-white px-6 justify-content-end p-2">
                        <p className="text-base tracking-widest uppercase">
                          Share —{' '}
                        </p>
                        <FacebookShareButton
                          url={siteurl + '/blog/' + post.slug}
                          quote={post.title}
                          hashtag={'#usqualityconstruction'}
                          className="bg-white hover:bg-gray-200 cursor-pointer flex h-8 items-center p-1 rounded-full w-8 mr-1"
                        >
                          <Facebook size={20} />
                        </FacebookShareButton>

                        <TwitterShareButton
                          url={siteurl + '/blog/' + post.slug}
                          title={post.title}
                          hashtags={post.tags}
                          className="bg-white hover:bg-gray-200 cursor-pointer flex h-8 items-center p-1 rounded-full w-8 mr-1"
                        >
                          <Twitter size={20} />
                        </TwitterShareButton>
                        <LinkedinShareButton
                          url={siteurl + '/blog/' + post.slug}
                          title={post.title}
                          quote={post.title}
                          hashtags={post.tags}
                          className="bg-white hover:bg-gray-200 cursor-pointer flex h-8 items-center p-1 rounded-full w-8 mr-1"
                        >
                          <Linkedin size={20} />
                        </LinkedinShareButton>
                        <EmailShareButton
                          subject={post.title}
                          url={siteurl + '/blog/' + post.slug}
                          openWindow={true}
                          body={'Check out this article: '}
                          className="bg-white hover:bg-gray-200 cursor-pointer flex h-8 items-center p-1 rounded-full w-8 mr-1"
                        >
                          <Mail size={20} className="stroke-white" />
                        </EmailShareButton>
                      </ShareThisPost>
                    </div>
                  </div>
                </div>
                <div className="w-full lg:w-1/3"></div>
              </div>
            </Header>
            <div className="lg:flex lg:container lg:row mx-auto">
              <div className="relative articleBody w-full lg:w-2/3 z-10">
                <div
                  style={{ marginTop: '110px' }}
                  itemProp="articleBody"
                  dangerouslySetInnerHTML={{ __html: post.body.body }}
                />
                <p>
                  Tags:{' '}
                  {post.tags.map((item, i) => (
                    <span itemProp="keywords" key={i}>
                      {' ' + item},
                    </span>
                  ))}
                </p>
              </div>
              <div className="w-full lg:w-1/3"></div>
              <div className="lg:container block lg:flex lg:row w-full h-full top-0 justify-end relative lg:absolute">
                <div className="w-full lg:w-2/3 invisible" />
                <div className="w-full lg:w-1/3 py-12 relative justify-self-end p-6">
                  <ContactCard className="border-radius p-6 cardborder shadow-lg sticky top-0">
                    <h3 className="text-center mb-1">Get Started Today!</h3>
                    <p class="block text-center mb-6 text-base font-semibold">
                      Request a FREE In-Home Estimate
                    </p>

                    <div className="mb-6 sidebarform">
                      <ContactForm
                        showLabels
                        showPlaceHolders
                        pageLocation={
                          this.props.location.href
                            ? this.props.location.href
                            : 'Not provided'
                        }
                      />
                    </div>
                  </ContactCard>
                </div>
              </div>
            </div>
          </article>
        </BlogPostLayout>

        {relatedPosts.edges &&
          relatedPosts.edges &&
          relatedPosts.edges.length > 0 && (
            <div className="container mx-auto px-3 py-8">
              <h2>More in {post.category.name}</h2>
              <div className="grid grid-cols-2 gap-4 lg:grid-cols-3">
                {relatedPosts.edges.map((item, i) => (
                  <Link
                    key={i}
                    to={`/blog/${item.node.slug}`}
                    className="bg-white rounded-lg shadow hover:shadow-lg"
                  >
                    <GatsbyImage
                      image={getImage(item.node.heroImage)}
                      style={{ height: '150px' }}
                      alt={item.node.title}
                    />
                    <div className="p-3 py-4">
                      <span className="text-gray-700 text-base">
                        {item.node.publishDate}
                      </span>
                      <span className=" font-semibold leading-tight text-gray-900 block">
                        {item.node.title}{' '}
                      </span>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          )}

       
<AreasServed
          citiesList={this.props.data.citiesList.edges}
          serviceName={post.category.name}
          parentPageSlug={post.category.slug}
        />
       
        <RelatedProjects data={this.props.data.relatedProjects} />
      </Layout>
    )
  }
}

export default BlogTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $slug: String!
    $categoryId: String
    $testimonialCategorySlugExp: String
  ) {
    site {
      siteMetadata {
        siteUrl
      }
    }

    contentfulBlogPost(slug: { eq: $slug }) {
      title
      slug
      metaTitle
      metaDescription
      metaRobotsContent
      publishDate(formatString: "MMMM Do, YYYY")
      heroImage {
        gatsbyImageData(width: 1500, placeholder: BLURRED)
        metaTagImage: gatsbyImageData(
          width: 1200
          height: 630
          placeholder: BLURRED
        )
      }
      author {
        name
        slug
      }
      body {
        body
      }
      category {
        name
        slug
        description
      }
      tags
    }
    related: allContentfulBlogPost(
      filter: { category: { id: { eq: $categoryId } }, slug: { ne: $slug } }
      sort: { fields: publishDate, order: DESC }
      limit: 6
    ) {
      edges {
        node {
          id
          title
          publishDate(formatString: "MMMM Do, YYYY")
          slug
          heroImage {
            gatsbyImageData(width: 400, quality: 70, placeholder: NONE)
          }
          metaDescription
          author {
            name
          }
          category {
            name
          }
        }
      }
    }
    citiesList: allContentfulServiceArea(sort: { fields: location }) {
      edges {
        node {
          location
          slug
          zipCodes
          stateAbbreviation
        }
      }
    }
    contentfulBlogCategory(id: { eq: $categoryId }) {
      id
      name
      
    }
    relatedProjects: allContentfulProjects(
      filter: {
        category: {
          elemMatch: { slug: { regex: $testimonialCategorySlugExp } }
        }
      }
      limit: 4
    ) {
      edges {
        node {
          id
          title
          slug
          city
          zipCode
          category {
            slug
          }
          image: thumbnail {
            gatsbyImageData(width: 400, quality: 70, placeholder: NONE)
          }
        }
      }
    }
  }
`
